import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/main/Index.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/marketing/visitor.vue"),
        // component: () => import("@/views/home/Index.vue"),
      },
      // 精选产品
      {
        path: "/selected/products",
        name: "SelectedProducts",
        component: () => import("@/views/selected/products.vue"),
      },
      // 营销获客
      {
        path: "/marketing/visitor",
        name: "MarketingVisitor",
        component: () => import("@/views/marketing/visitor.vue"),
      },
      // 解决方案
      {
        path: "/solve/programme",
        name: "SolveProgramme",
        component: () => import("@/views/solve/programme.vue"),
      },
      // 关于我们
      {
        path: "/about/us",
        name: "AboutUs",
        component: () => import("@/views/about/us.vue"),
      },
    ],
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
